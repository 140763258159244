import React, { CSSProperties, FC } from "react"
import { Col, Container, Row } from "react-bootstrap"

import { HomeLayout } from "./layout/home"

const iosStore = "https://apps.apple.com/us/app/link-in-faith/id1527700630"
const androidStore =
  "https://play.google.com/store/apps/details?id=com.linkinfaith.linkinfaith"

type HomePageProps = {}

export const HomePage: FC<HomePageProps> = (props) => {
  return (
    <HomeLayout
      jumbotron={
        <div style={styles.container}>
          <Row>
            <Col>
              <h1>恩友 Link in Faith</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>Where brothers and sisters connect</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>繁體中文 | 简体中文 | English</h6>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6" className="my-2 text-center text-sm-right">
              <a href={iosStore}>
                <img
                  src="/images/appstore.png"
                  className="d-inline-block align-middle"
                  alt="App Store"
                  height={50}
                />
              </a>
            </Col>
            <Col xs="12" sm="6" className="my-2 text-center text-sm-left">
              <a href={androidStore}>
                <img
                  src="/images/google_play_store.png"
                  className="d-inline-block align-middle"
                  alt="Google Play Store"
                  height={50}
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                src="/images/mock_sample.png"
                className="d-inline-block align-bottom w-100"
                alt="App screenshots"
                style={{ maxWidth: 740 }}
              />
            </Col>
          </Row>
        </div>
      }
    >
      <div style={{ backgroundColor: "#f7f7f7" }}>
        <Container>
          <Row className="py-3 align-items-center">
            <Col xs="12" sm="6">
              <h3>教會弟兄姊妹分享社區</h3>
              <p>
                恩友App是一個讓教會弟兄姊妹互相分享近況動態、照片、屬靈筆記、讀經挑戰、代禱事項等的平台。
              </p>
              <ul>
                <li>
                  <b>恩友動態</b> -
                  發布帶有權限的近況動態（可選擇同工可見、本教會會友可見、所有人可見）。發起讀經挑戰，加強教會屬靈氣氛。一鍵加入Zoom會議，無需設置名字、會議ID和密碼。
                </li>
                <li>
                  <b>照片留念</b> -
                  教會活動過後，眾弟兄姊妹可以上傳自己拍攝的照片到同一個相冊，方便共享和管理照片留念。
                </li>
                <li>
                  <b>搜索教會</b> - 尋找附近的已經加入恩友平台的教會。
                </li>
                <li>
                  <b>快速聖經</b> -
                  使用拼音、拼音首字母、英文等快速打開聖經某一章節。同時支持多語言對照聖經。
                </li>
                <li>
                  <b>福音資源</b> - 可靠的信仰講道講座影片。
                </li>
                <li>
                  <b>未來功能</b> -
                  教會團契小組，操作簡單的在線奉獻，線上書局，音樂資源。
                </li>
              </ul>
              <p>下載恩友App - 讓弟兄姊妹不見面也能在神的愛中連結。</p>
            </Col>
            <Col xs="12" sm="6">
              <div
                style={{
                  backgroundImage: "url(/images/mock_2.png)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  minHeight: 400,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row className="align-items-center py-3">
          <Col xs="12" sm="6" className="d-flex align-items-center my-3">
            <img
              src="/images/logo.png"
              className="d-inline-block align-middle mr-3"
              height="70"
              alt="Link in Faith logo"
            />
            <div className="d-inline-block ">
              <div>下載恩友App</div>
              <div>Download Link in Faith</div>
            </div>
          </Col>
          <Col xs="12" sm="3" className="my-1 text-center">
            <a href={iosStore}>
              <img
                src="/images/appstore.png"
                className="d-inline-block align-middle"
                alt="App Store"
                height={50}
              />
            </a>
          </Col>
          <Col xs="12" sm="3" className="my-1 text-center">
            <a href={androidStore}>
              <img
                src="/images/google_play_store.png"
                className="d-inline-block align-middle"
                alt="Google Play Store"
                height={50}
              />
            </a>
          </Col>
        </Row>
      </Container>
    </HomeLayout>
  )
}

const styles: { [name: string]: CSSProperties } = {
  container: {
    textAlign: "center",
    color: "#fff",
    backgroundColor: "transparent",
  },
}
