import React, { FC } from "react"
import { Navbar } from "react-bootstrap"

export const HomeNavbar: FC = (props) => {
  return (
    <Navbar expand="lg">
      <Navbar.Brand href="/">
        <img
          src="/images/logo_white.png"
          width="30"
          height="30"
          className="d-inline-block align-top mr-3"
          alt="Link in Faith logo"
        />
        <span style={styles.white} className="d-none d-md-inline">
          恩友 Link in Faith
        </span>
      </Navbar.Brand>
      {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item>
            <Nav.Link href="/">Home</Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse> */}
    </Navbar>
  )
}

const styles = {
  white: { color: "#fff" },
}
