import "./App.css"

import React from "react"
import { isAndroid, isIOS } from "react-device-detect"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import { HomePage } from "./HomePage"
import { PrivacyPolicy } from "./pages/PrivacyPolicy"
import { TermsAndConditions } from "./pages/TermsAndConditions"

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            path="/join"
            render={() =>
              isIOS
                ? (window.location.href =
                    "https://apps.apple.com/us/app/link-in-faith/id1527700630")
                : isAndroid
                ? (window.location.href =
                    "https://play.google.com/store/apps/details?id=com.linkinfaith.linkinfaith")
                : (window.location.href = "/")
            }
          ></Route>
          <Route extact path="/privacypolicy" component={PrivacyPolicy} />
          <Route
            extact
            path="/termsandconditions"
            component={TermsAndConditions}
          />
        </Switch>
      </Router>
    </>
  )
}

export default App
