import React, { FC } from "react"
import { Container } from "react-bootstrap"

import { HomeNavbar } from "./navbar"

type HomeLayoutProps = {
  jumbotron?: JSX.Element
}

export const HomeLayout: FC<HomeLayoutProps> = (props) => {
  return (
    <>
      {props.jumbotron && (
        <div style={{ backgroundColor: "#4C74AB" }}>
          <Container>
            <HomeNavbar />
            {props.jumbotron}
          </Container>
        </div>
      )}

      {props.children}

      <footer className="footer mt-auto py-3" style={styles.footer}>
        <div className="container">
          <span className="text-muted">Link in Faith Inc. &copy; 2020</span>
          &nbsp;|&nbsp;
          <a className="text-muted" href="/privacypolicy">
            Privacy Policy
          </a>
          &nbsp;|&nbsp;
          <a className="text-muted" href="/termsandconditions">
            Terms &amp; Conditions
          </a>
        </div>
      </footer>
    </>
  )
}

const styles = {
  footer: {
    backgroundColor: "#eee",
  },
}
